/* .card-general-mainn { */
.bankAccountCard {
  height: 100%;
  min-height: 217px;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  transition: 0.3s;
  /* cursor: pointer; */
  padding: 10px;
  margin: 5px;
}

.image-icons-bank {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-currency {
  /* font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-right: 10px; */
  margin-top: 15px;
  /* color: #868686; */
}

.image-logo-card {
  width: 100px;
  height: 'auto';
  margin-top: 10px;
  margin-left: 8px;
  /* filter: drop-shadow(0 0 0.4px); */
}

.three-dot-icon {
  margin-top: 20px;
  cursor: pointer;
}

.iban-number-textt {
  /* font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px; */
  /* margin-top: 10px; */
  /* color: #868686; */
}

.msg-textt {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  overflow: hidden;
  line-height: 22px;
  text-align: justify;
  /* identical to box height */
  word-break: break-all;
  margin-top: 30px;
  margin-bottom: 0px;
  color: black;
}

.text-content-card-general {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.iban-numbers {
  /* font-family: "Montserrat";
  font-style: normal; */
  font-weight: 500;
  font-size: 14px;
  /* width: 60%; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  /* line-height: 22px; */
  /* identical to box height */
  word-break: break-all;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #1A1A1A;
}

.name-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #313131;
}

.name-date {
  display: flex;
  justify-content: space-between;
}

/* .date-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-right: 10px;
  color: #868686;
} */
/* .eye-Iconss:hover{
  background: linear-gradient(248.17deg, #82C8ED 0%, #A2C4FA 100%);
} */
.eye-Iconss {
  color: #006FF4;
}

.card-name-textt {
  /* font-family: "Montserrat";
  font-style: normal; */
  font-weight: 600;
  font-size: 16px;
  /* width: 70%; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  /* line-height: 16px; */
  margin-top: 15px;
  color: black;
}

/* Revamp BankCards  Css*/
.cardCurrencyAndIban {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #aab3bc;
}

.cardIban {
  font-family: "Montserrat";
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  /* line-height: 16px; */
  color: #000;
}

.card-blance-show {
  font-weight: 600;
  font-size: 16px;
  color: black;
  padding-top: 10px;
  padding-bottom: 5px;
}

.cardSelected {
  min-height: 217px;
  max-height: 100%;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(75, 154, 244, 1) 0%, rgba(101, 187, 244, 1) 100%);
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}

.cardSelected .cardCurrencyAndIban {
  color: #c6def8;
}

.cardSelected .card-name-textt,
.cardSelected .cardIban,
.cardSelected .eye-Iconss {
  color: #FFFFFF;
}

/* Account Card Media Queries */
@media (max-width: 576px) {
  .bankAccountCard {
    height: 100%;
    min-height: auto;
    padding: 10px;
    margin: 5px;
  }
 

  .image-icons-bank img {
    width: 64.7px;
    height: 22.18px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .image-icons-bank span {
    margin-right: 0px !important;
  }

  .text-content-card-general {
    padding: 0px;
  }

  .text-content-card-general span:nth-child(n) {
    /* padding: 0px 0px 0px 0px !important; */
    margin: 10px 0px 0px 0px !important;
  }

  .card-name-textt {
    font-size: 10px;
    margin-top: 10px;
  }

  .cardCurrencyAndIban {
    font-size: 10px;
  }

  .iban-numbers {
    font-size: 10px !important;
    margin-top: 4px !important;
    margin-left: 0px !important;
    margin-bottom: 0px;
  }

}