.expense-payment{
  background-color: #FFFFFF;
  border-radius: 15px;
}

.expense-payment-text {
  display: flex;
  justify-content: center;
  margin-top: 13px;
}
.expense-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  cursor: pointer;
  color: #191919;

}
.month-filter-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 15px;
  /* identical to box height */

  color: #ADB6C0;
}
.filter-month-main {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 10px;
  cursor: pointer;
}
.limit-remainder-main {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.limit-remainder-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ADB6C0;
  margin: 0;
}
.limit-remainder-amount {
  display: flex;
  justify-content: space-between;
}
.limit-amount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  margin: 0;
  color: #191919;
}
.all-operation-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
}
.all-operation-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #313131;
}

@media screen and (max-width: 427px) {
.expense-payment{
  margin-top: 15px;
}
}

@media screen and (max-width: 322px) {
  .expense-text{
  font-size: 18px;
}
}