.modalHeader{
    display: flex;
    align-items: center;
    border-bottom: none !important;
    position: sticky;
    top: -2px;
    z-index: 1050;
    background-color: #ffffff;
}
.withShadow{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.headerDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerDiv .modal-title {
    font-weight: 600;
    color: #231F20;
    margin-left: 20px;
}

@media screen and (max-width: 576px) {
    .modal-cross-icon { margin-right: 0px !important;}
}
