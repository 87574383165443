.searchBox {
  position: absolute;
  padding: 11px 35px 11px 11px;
  border: none;
  background: #ffffff;
  border-radius: 10px;
}

.iconsClass {
  position: relative;
  left: 214px;
  top: 10px;
  color: #7ca4e8;
}

.transactionFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.transaction-searchBox {
  background: #ffffff;
  border-radius: 10px;
  padding: 5px;
}

.searchBox-icon {
  color: #b9e7ff;
}

.transactionDateFilter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.transactionDate {
  height: 40px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.dateIcons {
  display: block;
  font-size: 30px;
}

.searchContainer {
  display: flex;
  align-items: center;
  border: none;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input {
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

.dateFilterBox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.dateFilters {
  width: 100%;
  margin: 0;
}

.tableText {
  font-size: 24px;
  font-family: "Montserrat";
  color: rgba(173, 182, 192, 1);
  margin: 0px
}

.css-ubpweo-MuiPaper-root-MuiPopover-paper{
  border-radius: 20px !important;
  font-family: "Montserrat" !important;
}

@media screen and (max-width: 1024px) {
  .dateFilters {
    margin-top: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .dateIcons {
    display: none;
  }

  .transactionDateFilter {
    padding: 0px !important;
    justify-content: center !important;
  }

  .dateFilterBox {
    padding: 0 !important;
  }

  .dateFilterInput {
    width: 100%;
    margin: 5px 0;
  }
}