.Enter {
  display: flex;
  min-height: 100vh;
}

/* .left {
  width: 50%;
  background: linear-gradient(27.46deg, #aec3ff 0%, #75cae8 101.76%);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
} */


.logo-box {
  width: 100%;
  height: 93px;
}

/* .logo {
  width: 51px;
  height: 58px;
} */
.logo-title {
  font-weight: 800;
  color: #272727;
  line-height: 31px;
  font-size: 25px;
}

/* .logo-wrapper {
  margin-top: 40px;
  margin-left: 40px;
  display: flex;
  width: 148px;
  align-items: center;
} */
/* .left-title-box {
  width: 477px;
  height: 292px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column;
  padding-left: 50px;
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
} */
/* .left-title-box img{
  max-width: 100%;
  height: auto;
}
.left-title,
.left-title-second,
.left-title-third {
  font-weight: 600;
  font-size: 65px;
  color: #313131;
} */
.welcome-box {
  width: 359px;
  height: 69px;
  text-align: center;
  margin-bottom: -140px;
}

.welcome {
  font-weight: 500;
  font-size: 30px;
  color: #313131;
}

.after-welcome {
  color: #313131;
}

/* .circle-box {
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
} */
/* .blue-circle {
  width: 439px;
  height: 439px;
  border-radius: 50%;
  background-color: #70ade6;
  position: absolute;
  bottom: 0px;
  z-index: -1;
  opacity: 0.6;
  left: -80px;
  bottom: -290px;
} */
/* .white-circle {
  width: 400px;
  height: 400px;
  background-color: #b1c9ff;
  opacity: 0.6;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  bottom: -290px;
  z-index: -2;
} */
/* .right {
  width: 50%;
  background-color: #ffffff;
  padding: 135px 50px 80px 50px;
} */

.lng-dropdown {
  display: flex;
  position: absolute;
  top: 10px;
  right: 24px;
}

.email-inp {
  margin-bottom: 40px;
}

.email-inp,
.pass-inp {
  height: 50px;
  background: #edf2f7;
  border: none;
  border-radius: 5px;
  font-family: inherit;
  padding: 0.375rem 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.pass-inp {
  margin-bottom: 15px !important;
}

.email-inp::placeholder,
.pass-inp::placeholder {
  font-weight: 500;
  font-size: 20px;
  color: #cbcace;
}

.pass-text {
  margin-top: 30px;
}

/* .forgot-link {
  font-size: 20px;
  color: #86c8ef;
  text-decoration: none;
  margin-top: 30px;
} */

.forgot-link:hover {
  text-decoration: underline;
}

.sign-btn,
.reg-btn {
  background: #60a3f3;
  border-radius: 5px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  height: 50px;
  border: none;
  margin-bottom: 30px;
  cursor: pointer;
}

.sign-btn:hover,
.reg-btn:hover {
  background: #92bafb;
}

.sign-google {
  width: 269px;
  height: 52px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #5186ec;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  color: white;
  cursor: pointer;
  padding-right: 25px;
}

.google-img {
  background-color: white;
  padding: 7px;
  margin-left: -3px;
  border-radius: 5px;
}

.enter-form {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.btn-box {
  display: flex;
  flex-flow: column;
  margin-top: 50px;
}

/* Revamp design css */
.SignIn {
  background: #fff;
}

.form_section {
  padding: 25px 50px !important;
}
.inputPassDiv {
  position: relative;
}

.inputPass {
  width: 100%;
}

.showPassIcon {
  color: #adb6c0;
  cursor: pointer;
}

.viewPassIcon {
  position: absolute;
  right: 20px;
  top: 49%;
  transform: translateY(-50%);
}
.inputTextBox {
  border: 2px solid #D7F0FF !important;
  border-radius: 10px !important;
  padding: 10px 15px !important;
  background-color: #fff !important;
}

.rememberMe-forgotPassDiv {
  display: flex;  
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.rememberMe .form-check-input{
  border: 1px solid #006FF4;
}

.forgot-link {
  color: #006FF4;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
}



@media (max-width: 767px) {
  .loginBG {
    display: none;
  }
}

@media (max-width: 540px) {
  .lng-dropdown{
    position: relative;
    justify-content: end;
    margin-top: 15px;
    top: 0px;
    right: 0px;
  }
  .form_section {
    padding: 15px !important;
  }
}
@media (max-width: 430px) {
  
}

@media (max-width: 321px) {
  .rememberMe-forgotPassDiv {
    display: block;
    margin-top: 15px;
  }
}

@media (max-width: 380px) {
  .forgetPass {
    text-align: end;
  }
}

/* Revamp design css */