* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f3f8fd; */
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  background-color: #f3f8fd;
}

.passwordReqText{
  font-size: 14px;
  margin: 0;
  margin-top: 20px;
  color: #ADB6C0;
}

/* My Custom Css for general design  */

/* .inner__content {
  margin-left: 300px;
  margin-right: 260px;
  padding: 20px;
} */
.inner__contentInvoice {
  /* margin-left: 260px;
  margin-right: "100%"; */
  /* padding: 2px; */
}

.MuiFormControlLabel-label {
  font-family: "Montserrat", sans-serif !important;
}

.bm-burger-button {
  display: none;
}

.hamburgerBackground {
  display: none;
}

@media (min-width: 1200px) {
  .Acc-switch {
    display: none;
  }
  .interest-account-container{
    display: none;
  }
}

@media (min-width: 765px)and (max-width: 1248px) {
  /* .inner__content {
    margin-left: 245px !important;
    margin-right: 235px !important;
    padding: 12px !important;
  } */
  .inner__contentInvoice {
    /* margin-left: 260px;
    margin-right: "100%"; */
    /* padding: 2px; */
  }
  .right-bar {
    right: 175px !important;
  }

  .generalInfo-heading {
    font-size: 16px !important;
  }

  .Acc-switch {
    display: none !important;
  }

  .interest-account-container{
    display: none !important;
  }

  .recent-op-text {
    font-size: 16px !important;
  }

  .recent-op-rates-box {
    overflow: hidden !important;
    overflow-x: scroll !important;
  }

  .privacy-policy-content-table {
    overflow: hidden !important;
    overflow-x: scroll !important;
  }

  /* .amount-op-text {
    font-size: 12px !important;
  } */

  .brandName-text {
    font-size: 12px !important;
  }

  .addbeneficiary .right-bar {
    right: -15px !important;
  }
 

  .addbeneficiary .addbene-card-box {
    padding: 10px 15px !important;
  }

  .addbeneficiary .addbenef-form {
    /* width: 235px !important; */
    width: 90%;
  }

  .addbeneficiary .ent-num-inp-text {
    font-size: 14px !important;
  }

  .addbeneficiary .ent-amount-inp-text {
    font-size: 14px !important;
  }

  .addbeneficiary .comment-inp-text {
    font-size: 14px !important;
  }
/* Invoice Css start */
.invoice .right-bar {
  right: -15px !important;
}
.invoice .invoice-card-box {
  padding: 10px 15px !important;
}

.invoice .invoice-form {
  width: 235px !important;
}

.invoice .ent-num-inp-text {
  font-size: 14px !important;
}

.invoice .ent-amount-inp-text {
  font-size: 14px !important;
}

.invoice .comment-inp-text {
  font-size: 14px !important;
}
.invoice .invoice-form {
  width: 100% !important;
}
.invoice .invoice-form{
  width: 100% !important;
}
.invoice .invoice-form{
  width: 235px !important;
}

/* Invoice Css ends */
  .Convert-info-main .right-bar {
    right: -15px !important;
  }

  .Convert-info-main .convert-card-box-bar {
    padding: 10px 15px !important;
  }

  /* .Convert-info-main .convert-form {
    width: 90%;
  } */

  .Convert-info-main .ent-num-inp-text {
    font-size: 14px !important;
  }

  .Convert-info-main .ent-amount-inp-text {
    font-size: 14px !important;
  }

  .Convert-info-main .from-inp-text {
    font-size: 14px !important;
  }

  .Convert-info-main .to-inp-text {
    font-size: 14px !important;
  }

  .SendFunds .right-bar {
    right: -15px !important;
  }

  .SendFunds .send-card-box {
    padding: 10px 15px !important;
  }

  .SendFunds .sendf-form {
    width: 80% !important;
  }

  .SendFunds .ent-num-inp-text {
    font-size: 14px !important;
  }

  .SendFunds .ent-amount-inp-text {
    font-size: 14px !important;
  }

  .SendFunds .comment-inp-text {
    font-size: 14px !important;
  }

  .General-info-main .right-bar {
    right: -15px !important;
  }

  .privacy-policy-main .right-bar {
    right: -15px !important;
  }

  .footer .footer-text {
    display: block;
  }

  .footer {
    position: static;
  }

  /* .privacy-policy-main .privacy-policy-title{
    font-size: 17px !important;
  } */
  .General-info-main .card-balance-box_wrapper {
    flex-direction: column !important;
    padding: 16px 12px 24px 15px !important;
  }

  .balance {
    margin-bottom: 12px;
  }

  .card-balance-text {
    margin-bottom: 12px;
    font-size: 17px;
  }

  .General-info-main .card-balance-box {
    margin: 0px 6px 10px 0px !important;
  }

  .card-balance-btn-wrapper {
    align-self: center;
  }

  .card-balance-box-text {
    align-self: center;
    padding-top: 18px;
  }

  .left-title-box {
    margin-left: 50px;
  }

  .Enter .left-title {
    font-size: 50px;
  }

  .Enter .left-title-second {
    font-size: 50px;
  }

  .Enter .left-title-third {
    font-size: 43px;
  }

  .Registration .left-title {
    font-size: 50px;
  }

  .Registration .left-title-second {
    font-size: 50px;
  }

  .Registration .left-title-third {
    font-size: 43px;
  }

  .Verification .left-title {
    font-size: 50px;
  }

  .Verification .left-title-second {
    font-size: 50px;
  }

  .Verification .left-title-third {
    font-size: 43px;
  }

  .ForgotPassword .left-title {
    font-size: 50px;
  }

  .ForgotPassword .left-title-second {
    font-size: 50px;
  }

  .ForgotPassword .left-title-third {
    font-size: 43px;
  }

  .NewPassword .left-title {
    font-size: 50px;
  }

  .NewPassword .left-title-second {
    font-size: 50px;
  }

  .NewPassword .left-title-third {
    font-size: 43px;
  }

  .Bank-info-main .right-bar {
    right: -15px !important;
  }

  /* .Bank-info-main .main-payment-receivers-container {
    display: block !important;
  } */

  .verification-form .send-btn {
    width: 100%;
  }

  .Bank-info-main .main-payment-receivers-container .payment-receiver-mainn {
    /* width: 100% !important; */
    margin-bottom: 20px !important;
  }

  /* .Bank-info-main .main-payment-receivers-container .border-dashed-card {
    width: 100% !important;
  } */

  .Interest-account-main .interest-balance-box_wrapper {
    flex-direction: column !important;
    padding: 16px 12px 24px 15px !important;
  }

  .balance {
    margin-bottom: 12px;
  }

  .interest-balance-text {
    margin-bottom: 12px;
    font-size: 17px;
  }

  .Interest-account-main .interest-balance-box {
    margin: 0px 6px 10px 0px !important;
  }

  .interest-balance-btn-wrapper {
    align-self: center;
  }

  .interest-balance-box-text {
    align-self: center;
    padding-top: 18px;
  }

}

@media (min-width: 320px) and (max-width: 760px) {
  .main-summarybox-table {
    max-height: 300px;
    overflow-y: scroll;
  }

  .summary-box-tablee {
    overflow-y: scroll;
  }

  .hamburgerBackground {
    display: block;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 99999;
    height: 50px;
  }

  .pb-5 {
    padding-top: 3rem !important;
  }

  .bm-burger-button {
    display: block;
    top: 16px;
  }

  .enter-inp {
    width: 100%;
  }

  .left {
    display: none;
    /* width: 100%; */
  }

  .Enter {
    display: block;
  }

  .right {
    width: 100%;
  }

  .ver-right {
    width: 100%;
  }

  .verification-form .send-btn {
    max-width: 100%;
  }

  .right-bar {
    display: none;
  }

  .left_sidebar {
    display: none;
  }

  .inner__content {
    margin-left: 0;
    margin-right: 0;
  }
  /* .inner__contentInvoice {
    margin-left: 0;
    margin-right: "100%";
  } */

  .Convert-info-main .convert-form {
    width: 100% !important;
  }

  .SendFunds .sendf-form {
    width: 100% !important;
  }

  .sendF-modal {
    margin-top: 50px;
  }

  .addbeneficiary .addbenef-form {
    width: 100% !important;
  }
  .invoice .invoice-form{
    width: 100% !important;
  }

  /* .Bank-info-main .main-payment-receivers-container {
    display: block !important;
  } */

  .Bank-info-main .main-payment-receivers-container .payment-receiver-mainn {
    /* width: 100% !important; */
    margin-bottom: 20px !important;
  }

  .recent-op-rates-box {
    overflow: hidden !important;
    overflow-x: scroll !important;
  }

  .recent-op-text {
    font-size: 16px !important;
  }

  .brandName-text {
    font-size: 12px !important;
  }

  /* .Bank-info-main .main-payment-receivers-container .border-dashed-card {
    width: 100% !important;
  } */

  .privacy-policy-content-table {
    overflow: hidden !important;
    overflow-x: scroll !important;
  }

  .footer .footer-text {
    display: block;
  }

  .generalInfo-heading {
    font-size: 16px !important;
  }

  .footer {
    position: static;
  }

  .General-info-main .card-balance-box_wrapper {
    flex-direction: column !important;
    padding: 16px 12px 24px 15px !important;
  }

  .balance {
    margin-bottom: 12px;
  }

  .card-balance-text {
    margin-bottom: 12px;
    font-size: 17px;
  }

  .General-info-main .card-balance-box {
    margin: 0px 6px 10px 0px !important;
  }

  .card-balance-btn-wrapper {
    align-self: center;
  }

  .card-balance-box-text {
    align-self: center;
    padding-top: 18px;
  }

  .Interest-account-main .interest-balance-box_wrapper {
    flex-direction: column !important;
    padding: 16px 12px 24px 15px !important;
  }

  .interest-balance-text {
    margin-bottom: 12px;
    font-size: 17px;
  }

  .Interest-account-main .interest-balance-box {
    margin: 0px 6px 10px 0px !important;
  }

  .Interest-account-main .interest-account-card-wrapper
  {
    margin-right: 0px !important;
  }

  .interest-balance-text {
    margin-bottom: 12px;
    font-size: 17px;
  }

  .interest-balance-btn-wrapper {
    align-self: center;
  }

  .interest-balance-box-text {
    align-self: center;
    padding-top: 18px;
  }

}

.left_sidebar {
  width: 250px;
  top: 0px;
  min-height: 100vh;
  background-color: #ffffff;
}

.right-bar {
  /* position: fixed; */
  right: 0;
  top: 0;
  height: 100%;
  /* width: 260px; */
  /* padding: 27px 22px 30px 22px; */
}

.profile-ico {
  position: relative;
  width: 50px;
  height: 50px;
}

.dropdown {
  width: 100%;
}

.dropbtn {
  width: 100%;
  height: auto;
  max-height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px 10px 10px 35px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-info {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}

.user-fname {
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  margin-bottom: 0;
  margin-left: 5px;
}

.user-lname {
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  margin-bottom: 0;
  margin-left: 5px;
}

.user-id {
  font-weight: 400;
  font-size: 10px;
  color: #868686;
  margin: 0;
  text-align: left;
}

.down-arrow {
  margin-right: 6px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  width: 100%;
}

.dropdown-content ul {
  list-style: none;
}

.dropdown-content a {
  height: 50px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.account-ico {
  margin: 0px 15px 0px 30px;
}

.dropdown-acc {
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.notificatons-btn {
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  /* margin-bottom: 20px; */
}

.not-text {
  margin-right: 3rem;
}

.not-drp-text {
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 10px !important;
}

/* Revamp CRM CSS Start */
.onBoardingImage {
  display: block;
  width: 50%;
}
.onBoardingForm {
  width: 50%;
  background-color: #ffffff;
  padding: 50px;
}
.onBoardingBGImg {
  background: url('assets/images/revamp/onboardingBackground2.png') center/cover no-repeat;
  /* background-repeat: no-repeat; */
  position: relative;
  /* background-size: cover; */
  /* height: 100vh; */
  /* background-color: #0d6efd; */
  border-radius: 15px;
  background-color: rgb(79,157,247);
  height: 99vh;
}
.omwLogo{
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}
.inputLabel {
  font-size: 16px;
  color: #231F20;
  margin-bottom: 7px;
  font-weight: 500;
}
.onBoarding-title {
  font-weight: 700;
  font-size: 30px;
  color: #231F20;
}
.onBoarding-text {
  font-weight: 400;
  font-size: 18px;
  color: #ADB6C0;
  margin-bottom: 40px;
}

.onBoardingFooterText {
  text-align: center;
}

.onBoardingFooterText p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
}

.onBoardingFooterText span {
  color: #006FF4;
  font-weight: 500;
  cursor: pointer;
}

/* Form Section Styling */
.mainHeading{
  font-size: 30px;
  font-weight: 600;
  color:#231F20;
  margin-bottom: 30px;
}

.formInputLabel{
  font-size: 18px;
  font-weight: 500;
  color: #231F20;
  margin-bottom: 10px;
  margin-top: 25px;
}
.btn-send{
  margin-top: 40px;
}
/* Form Section Styling */

.basicFormWidth {
  width: 470px;
  margin: 0px auto;
  padding: 15px 30px 20px 30px;
}

.btnSubmit {
  background-color: #006FF4  !important;
  color: #FFFFFF !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  padding: 10px !important;
}
.btnSubmit:hover{
  background-color: #006FF4  !important;
  color: #FFFFFF !important;
}
/* Modal Css Start */
.modal-header{
  border: none;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.modal-body table tr td {
  border: none;
}

.modal-header .btn-close{
  background-color: #006FF4;
  color: #FFFFFF !important;
  border-radius: 50%;
}

.modal-header .btn-close:hover{
  /* background-color: #006FF4; */
  color: #FFFFFF !important;
  /* border-radius: 50%; */
}
/* Modal Css End */

/* Revenue Tracker Right sidebar */
.monthlyRevenueTrackerDiv{
  max-height: 450px;
}
/* Revenue Tracker Right sidebar */

.mainFormBox {
  background-color: #FFFFFF;
  border-radius: 15px;
}

/* DropDown Styling */
.basicDropDownStyles {
  border: 2px solid #D7F0FF;
  border-radius: 10px;
  color: #ADB6C0;
  padding: 10px;
}
.basicDropDownStyles .dropdownOption{
background-color: #FFFFFF;
color: #000;
}
/* DropDown Styling */

/* SWAL( Sweet Alert ) Modal */
.swal2-modal  {
  border-radius: 16px;
}
.swal2-modal .swal2-actions {
  width: 100% !important;
}
.swal2-modal .swal2-title {
  color: #231F20;
}
.swal2-modal .swal2-html-container {
  color: #ADB6C0;
}

.modalBtnOK{
  background-color:#006FF4 !important;
  width: 80% !important;
  border-radius: 10px !important;
}
.modalBtnConfirm{
  width: 80% !important;
  border-radius: 10px !important;
}
.modalBtnCancel{
  width: 80% !important;
  border-radius: 10px !important;
  background-color: #FFFFFF !important;
  border: 2px solid #006FF4 !important;
  color: #006FF4 !important
}
.modalBtnCancel:hover{
  background-color: #FFFFFF !important;
  background-image: none !important;
}

/* login, register, forgot pass banner */
.top-banner{
  position: relative;
  background-image: url("./assets//images/revamp/loign-banner.png");
  height: 220px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  border-radius: 16px;
  display: none;
}
.top-banner img {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}
.errorMsg {
  color: red;
}
.hide-new-messages span[data-id="new_messages"] {
  display: none !important;
}

/* Media Queries */

/* SWAL Modal buttons */
@media (max-width: 540px) {
  .onBoardingImage{
    display: none;
  }
  .mainHeading{
    font-size: 25px;
  }
  .onBoardingForm {
    width: 100%;
  }
  .onBoarding-text{
    font-size: 14px;
  }
}

@media (max-width: 1030px) {
  .basicFormWidth{
    width: 100%;
  }
}
@media (max-width: 576px) {
  .swal2-image { width: 70px; height: 70px;}
  .swal2-title { font-size: 16px;}
  .swal2-html-container { font-size: 12px !important;}
  .swal2-actions button { font-size: 14px !important;}
  .mainHeading {font-size: 20px;}
  .formInputLabel {font-size: 16px;}
  .accordion button {font-size: 14px;}
  
  .basicFormWidth{padding: 15px 10px 20px 10px;}
  /* .modal-content{transform: translateY(30%);} */
  .errorMsg { font-size: 12px; }
}
@media (max-width: 540px) {
  .top-banner {display: block;}
  .onBoardingForm { padding: 10px; }


}

@media (max-width: 430px) {
  .basicFormWidth{
    /* padding: 17px 2px; */
  }
  }
/* Revamp CRM CSS end */