.interestAccount-heading {
    width: 265px;
    height: 30px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 30px;
    color: #313131;
  }
  .heading_title {
    padding-bottom: 20px;
  }
  .activity-chart {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    padding: 3.5rem;
  }
  
  .Acc-switch {
    padding: 0 !important;
  }
  

  .company_title {
    font-weight: 700;
    font-size: 25px;
    color: #313131;
    padding-bottom: 20px;
  }

  .company-card-box {
    width: 100%;
    height: 100%;
    min-height: 450px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px 30px;
  }

  .company-form {
    /* width: 470px; */
    margin: 20px auto;
  }

  .ent-num-inp-text,
.ent-amount-inp-text,
.comment-inp-text {
  font-weight: 600;
  font-size: 20px;
  color: #313131;
  margin-bottom: 2px;
  margin-left: 3px;
}