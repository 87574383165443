.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Adjust the height as needed */
  font-size: 1.5rem;
}

.availableAmountDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #d7f0ff;
  border-radius: 10px;
  padding: 10px;
  width: 290px;
}

.availableAmountDiv p {
  margin: 0;
  font-weight: 600;
}

.availableAmountLabel {
  color: #819fc0;
  font-size: 12px;
}

.availableAmount {
  color: #006ff4;
  font-size: 17px;
}

/* Card Css Start */
.cardDetails {
  justify-content: center;
  align-items: center;
  width: auto;
}

.cardDetails .card-container .cardDetailsCardBody {
  border-radius: 25px;
  width: 100%;
  /* width: 255px; */
  padding: 20px 10px 10px 13px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  color: #ffffff;
  margin: 5px 0px 10px 0px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s, box-shadow 0.3s;
  background-image: url("../../../../assets/images/cardAssets/omwVirtualCardTemplate.svg");
  /* background-size: cover; */
  background-repeat: no-repeat;
}

.detailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardDetails .card-container .maskedCardNumberDiv p,
.cardDetails .card-container .cardHolderDiv p {
  margin-bottom: 0;
}

.cardDetails .card-container .maskedCardNumberDiv {
  display: flex;
  /* justify-content: center; */
  margin: 15px 0px;
}

.cardDetails .card-container .maskedCardNumber {
  font-family: "Courier New", monospace;
  letter-spacing: 0.07em;
  font-size: 18px;
}

.cardDetails .card-container .cardHolderDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.cardDetails .card-container .cardHolderName {
  font-family: "Courier New", monospace;
  font-size: 14px;
  margin-top: 14px;
}

.cardTemplateViewDetails {
  width: 300px;
  padding: 0;
  margin: 10px;
  /* border-radius: 12px; */
  border-radius: 14px;
  box-shadow: 0px 0px 15px 10px rgba(0, 27, 58, 0.1);
}

/* Card Css end */
.cardDetailsDiv {
  width: 290px;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 2px rgba(0, 27, 58, 0.06);
  padding: 15px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardDetailsLabel {
  font-size: 12px;
  color: rgba(173, 182, 192, 1);
  /* line-height: 14.63px; */
}

.cardDetailsValue {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
}

.expiryDateLabel,
.expiryCVVLabel {
  color: #b4bdc5;
  font-size: 12px;
}

.expiryDate,
.expiryCVV {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.cardExpCVV {
  width: 100%;
  margin-top: 20px;
}



@media only screen and (max-width: 335px) {
  .cardDetails .card-container .maskedCardNumber {
    font-size: 19px;
  }

  .availableAmountDiv {
    width: 100%;
  }
}

/* ========= ifame css ======== */
/* ========= the below have one extra media query that used by component ======== */
/* ========= ".cardExpCVV" this class is not used by iframe              ======== */
/* ========= gave css after removing this line to ifram company          ======== */
#card-number-with-spaces {
  color: white;
  letter-spacing: 4px;
  position: absolute;
  font-family: monospace;
  font-size: 16px;
  top: 138px;
  left: 101px;
}

#card-exp-date-with-slash {
  font-weight: 600;
  color: black;
  letter-spacing: 1px;
  position: absolute;
  font-size: 16px;
  font-family: monospace;
  top: 250px;
  left: 20%;
}

#card-cvv {
  font-weight: 600;
  color: black;
  letter-spacing: 1px;
  position: absolute;
  font-size: 16px;
  font-family: monospace;
  top: 304px;
  left: 20%;
}

/* 
@media only screen and (max-width: 1024) {
  #card-number-with-spaces { left: 23% }
}
@media only screen and (max-width: 912px) {
  #card-number-with-spaces { left: 26% }
  #card-exp-date-with-slash{ left: 23%; }
  #card-cvv { left: 23%; }
}
@media only screen and (max-width: 768px) {
  #card-exp-date-with-slash{ left: 18%; }
  #card-cvv { left: 18%; }
}
@media only screen and (max-width: 575px) {
  #card-number-with-spaces { letter-spacing: 3; left: 30%; }
  #card-exp-date-with-slash{ left: 27%; } 
  #card-cvv { left: 27%; }
  .cardExpCVV {width: 48%;}
}
@media only screen and (max-width: 550px) {
  #card-number-with-spaces { left: 29%; }
  #card-exp-date-with-slash{ left: 23%; }
  #card-cvv { left: 23%; }
  .cardExpCVV { width: 50%;} 

}
@media only screen and (max-width: 525px) {
  #card-number-with-spaces { left: 28%; }
  #card-exp-date-with-slash{ left: 24%; }
  #card-cvv { left: 24%; }
  .cardExpCVV { width: 53%;} 
}
@media only screen and (max-width: 500px) {
  #card-number-with-spaces { left: 27%; }
  #card-exp-date-with-slash{ left: 23%; }
  #card-cvv { left: 23%; }
  .cardExpCVV { width: 55%;} 
}

@media only screen and (max-width: 475px) {
  #card-number-with-spaces { left: 26%; }
  #card-exp-date-with-slash{ left: 22%; }
  #card-cvv { left: 22%; }
  .cardExpCVV { width: 59%;}
}
@media only screen and (max-width: 450px) {
  #card-number-with-spaces { left: 24%; }
  #card-exp-date-with-slash{ left: 20%; }
  #card-cvv { left: 20%; }
  .cardExpCVV { width: 62%;}
}
@media only screen and (max-width: 425px) {
  #card-number-with-spaces { left: 23%; }
  #card-exp-date-with-slash{ left: 19%; }
  #card-cvv { left: 19%; }
  .cardExpCVV { width: 66%;}
}
@media only screen and (max-width: 420px) {
  #card-number-with-spaces { left: 22%; }
}
@media only screen and (max-width: 407px) {
  #card-number-with-spaces { left: 21%; }
}



@media only screen and (max-width: 400px) {
  #card-exp-date-with-slash{ left: 17%; }
  #card-cvv { left: 17%; }
  .cardExpCVV { width: 70%;}
}
@media only screen and (max-width: 394px) {
  #card-number-with-spaces { left: 20%; }

}


@media only screen and (max-width: 380px) {
  #card-number-with-spaces { left: 19%;}
  #card-exp-date-with-slash{ left: 16%; }
  #card-cvv { left: 16%; }
  .cardExpCVV { width: 73%;}
}

@media only screen and (max-width: 370px) { 
  #card-number-with-spaces { left: 18%;}
  #card-exp-date-with-slash{ left: 15%; }
  #card-cvv { left: 15%; }
  .cardExpCVV { width: 75%;}
}
@media only screen and (max-width: 360px) {
  #card-number-with-spaces { left: 17%;}
  #card-exp-date-with-slash{ left: 13%; }
  #card-cvv { left: 13%; }
  .cardExpCVV { width: 80%;}
}
@media only screen and (max-width: 350px) {
  #card-number-with-spaces { left: 16%;}
  #card-exp-date-with-slash{ left: 12%; }
  #card-cvv { left: 12%; }
  .cardExpCVV { width: 82%;}
}

@media only screen and (max-width: 340px) {
  #card-number-with-spaces { left: 15%;}
  #card-exp-date-with-slash{ left: 11%; }
  #card-cvv { left: 11%; }
  .cardExpCVV { width: 84%;}
}
@media only screen and (max-width: 330px) {
  #card-number-with-spaces { left: 14%;}
  #card-exp-date-with-slash{ left: 8%; }
  #card-cvv { left: 8%; }
  .cardExpCVV { width: 90%;}
}
@media only screen and (max-width: 321px) {
  #card-number-with-spaces { letter-spacing: 3; left: 26;}
  #card-exp-date-with-slash{left: 110px;}
  #card-cvv {left: 120px;}
} */