.basicButton {
    width: 100%;
    border: none;
    border-radius: 10px;
    height: 40px;
    background-color: #006FF4;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 600;
}

.basicButton:disabled {
    cursor: default;
    /* border: 1px solid #999999; */
    background-color: #cccccc;
    color: #666666;
}

.basicButton:hover:not(:disabled) {
    background-color: #0b5ed7;
}

.suffixIcon {
    margin-left: 5px;
}
.prefixIcon {
    margin-right: 5px;
}