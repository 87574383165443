/* .Convert-info-main {
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  background-color: #f5f6f8;
} */
.generalInfo-heading {
  width: 265px;
  height: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #231F20;
}
.convert_title {
  font-weight: 700;
  font-size: 25px;
  color: #313131;
  padding-bottom: 20px;
}
/* .convert-card-box {
  width: 100%;
  height: auto;
  min-height: 450px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px 30px;
} */
/* .convert-form {
  width: 470px;
  margin: 20px auto;
} */
.convert-send-con {
  display: flex;
  flex-direction: row;
}
.convert-send {
  font-weight: 700;
  margin-left: 10px;
}
.ent-cnum-inp-text,
.ent-amount-inp-text,
.from-inp-text,
.to-inp-text {
  font-weight: 600;
  font-size: 20px;
  color: #313131;
  margin-bottom: 2px;
  margin-left: 3px;
}
.enter-cnum-inp::placeholder,
.ent-amount-inp-text::placeholder,
.from-inp-text::placeholder,
.to-inp-text::placeholder {
  font-weight: 500;
  font-size: 3rem !important;
  color: black;
}
.convert-btn {
  width: 313px;
  height: 50px;
  background: #86b6ef;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  margin-top: 22px;
}
.logo-receipt {
  display: flex;
  justify-content: flex-start;
}
.verify-titlee h3 {
  margin-bottom: 10px;
  font-weight: 600;
}
.verify-titlee p {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px !important;
}
.verify-titlee-icon {
  align-self: center;
  margin-bottom: 10px;
}

.verify-titlee {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  z-index: 5;
}
.card-send-dat {
  padding: 20px;
  margin-bottom: 10px;
  /* background-color: #86b6ef !important; */
  overflow: hidden;
}
.card-send-dat hr {
  margin: 0;
}
.receipt__data {
  overflow: hidden;
  padding: 20px 0px 0px 0px;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin: 10px 0 0px;
}
.fund-send-con {
  margin-bottom: 10px;
  padding-right: 0 !important;
}
.conversionDetailText p {
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 500;
  margin-top: 3px;
  color: #231F20;
}
.conversionDetailText h6 {
  font-size: 14px;
  margin-bottom: 0px;
  color: #ADB6C0;
  margin-top: 15px;
  font-weight: 400;
}

.timerModalHeading{
  font-size: 20px;
  font-weight: 600;
  color: #231F20;
}
.btnCloseDiv, .btnAuthorizePaymentDiv{
  width: 49%;
}
.btnClose{
  background-color: #FFFFFF;
  color:#006FF4
}
.addbeneficiary{
  margin: 1rem !important;
}

@media screen and (max-width: 576px) {
  .timerModalHeading{ margin-top: 14px; }
  .addbeneficiary{
    margin: 0.4rem !important;
  }
  .convert-modal-root .modal-title {margin-left: 0px; margin-bottom: 0px;}
}
