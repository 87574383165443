.font24 {
    font-size: 24px;
}

.privacyPolicy {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    margin: 0px 10px;
}

.privacy-policy-title {
    color: #231F20;
    font-weight: 600;
}
.privacy-policy-content-table th {
    font-weight: 600;
}

.Privacy-policy-paragraph {
    color: #1A1A1A;
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
}

.OMWFeesBox {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.OMWFeesheadings {
    font-size: 24px;
    font-weight: 600;
    color: #231F20;
}
 .fee-table-heading {
    font-size: 22px;
    font-weight: 600;
    color: #231F20;
}

.business-accounts-fee-table table thead tr th {
    font-family:'Montserrat';
    /* font-weight: 700; */
    color: #231F20;
    /* font-weight: 600; */

}
.business-accounts-fee-table table tbody tr td,  .business-accounts-fee-table .fee-table-cell{
    font-family:'Montserrat' !important;
    /* font-weight: 500; */
    color: #231F20;
}

@media (max-width: 576px) {
    .font24 { font-size: 16px; }
    .Privacy-policy-paragraph { font-size: 12px;}
    .privacy-policy-content-table th, .privacy-policy-content-table td {  font-size: 9px; padding: 0.5rem 0.2rem !important;}
    .privacy-policy-content-table ul {padding: 0px 0px 0px 15px;}
    .OMWFeesheadings { font-size: 14px; }
    .fee-table-heading { font-size: 12px; }
}
