.mainHeader {
  padding: 17px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 15px;
}

.headerActions {
  margin-right: 15px;
}

.hamburgerMenuButton {
  display: none;
  background-color: transparent;
  border: none;
  /* margin-right: 10px; */
}

.languageDiv {
  margin: 0px 30px 0px 0px;
}

.notificationDiv {
  margin: 0px 20px 0px 0px;
}

.userIconDiv {
  margin: 0px 5px;
}
.menu-love .MuiPaper-root{
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
}
.menu-love .MuiMenu-paper ul li div span{
  font-family: "Montserrat" !important;
}
.menu-love .MuiMenu-paper ul .lastLogin-lastSync div span{
  font-size: 12px !important;
}
.menu-love .MuiMenu-paper ul .lastLogin-lastSync{
  padding: 5px 16px 0px 16px !important;
}

.menu-love .MuiButtonBase-root {
  display: flex !important;
  flex-direction: flex-column !important;
  justify-content: start !important;
  align-items: center !important;
  padding: 6px 16px !important;
}
.header-page-name{
  color: #231F20;
  font-weight: 700;
  display: none;

}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  box-shadow: none !important;
  border-radius: 10px;
  /* box-shadow: 0px 5px 5px -3px #D7F0FF,0px 8px 10px 1px #D7F0FF,0px 3px 14px 2px #D7F0FF !important;  */
  /* box-shadow: 0px 0px 1px -3px #D7F0FF,0px 1px 0px 1px #D7F0FF,0px 0px 0px 2px #D7F0FF !important;  */
 
}

.useraccount-parent{
  display: flex ;
  flex-direction: column;
  gap: 10px;
  padding: 0px 16px;
  max-height: 153px; /* Adjust the height as needed */
  overflow-y: auto;
  padding-bottom: 10px;
}
.useraccount-child{
  display: flex;
  gap: 6px;
  border: 1px solid ref;
  align-items: center;
  cursor: pointer;
}
.swtichAccount-title {
  font-size: 16px;
  font-weight: 600;
  color: #231F20;
  font-family: "Montserrat" !important;
}
.username {
  font-size: 16px;
  margin: 0px;
  font-family: "Montserrat" !important;
}

.search-input-recent-op-modal::placeholder {
  font-size: 14px;
}
.input-container {
  position: relative;
  width: 100%;
}
.search-icon {
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 40%;
  color: #006FF4;
  transform: translateY(-50%);
  pointer-events: none; 
  transition: opacity 0.3s ease;
}
.input-container:focus-within .search-icon {
  opacity: 0;
}
.user-email{
  font-size: 12px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Montserrat" !important;

}
.radio-button {
  transform: scale(1.5);
}
.lastLogin-lastSync-text{
  font-size: 12px!important;
  font-family: "Montserrat" !important;
}
@media (max-width: 760px) {
  .mainHeader {margin: 0;}
  .email-dropdown-container {display: none;}


}
@media (max-width: 576px) {
  .mainHeader {    padding: 17px 14px 17px 14px;}
  .header-page-name{ font-size: 14px; display: block;}
  .hamburgerMenuButton {padding: 4px 6px 6px 0px;}
  .languageDiv {margin: 0px 10px 0px 3px;}
  .languageDiv div div {width: 120px; line-height: normal;}
  .languageDiv div div .langIcon { margin-left: 9px; margin-top: 4px;}
  .languageDiv div div .langIcon img { width: 17px; height: 17px;}
  .languageDiv div div .lang p {font-size: 14px; padding-left: 28px;  }
  .languageDiv div div .dropdownOpen  { width: 12px; height: 15px; top: 4px;}
  .languageDiv div div .dropdownOpen svg  { width: 12px; height: 12px;}
  .notificationDiv {margin: 0px 5px 0px 0px;}
  .notificationDiv img {width: 20px; height: 20px;}
  .headerActions {margin-right: 0px;}
  .userIconDiv {margin-right: 0px;}
  .userIconDiv button .MuiAvatar-root {width: 30px; height: 30px;}
  .menu-love .MuiMenu-paper ul li div span {font-size: 10px;}
  .menu-love .MuiButtonBase-root {min-height: 22px;}
  .swtichAccount-title {font-size: 12px;}
  .useraccount-child .MuiAvatar-root {width: 30px; height: 30px;}
  .username {font-size: 10px;}
  .useraccount-parent {max-height: 118px;}
  .search-input-recent-op-modal {padding: 1px 10px;}
  .search-input-recent-op-modal::placeholder{font-size: 10px;}
  .search-icon{ font-size: 16px; top: 35%;}
  .offcanvas-start { border-radius: 0px 10px 10px 0px !important; width: 240px !important;}
  .offcanvas-body { padding-right: 0px;}
  .menu-love {max-width: 220px;}
  .user-email{font-size: 8px;width: 100px;}
  .radio-button {transform: scale(1);}
  .lastLogin-lastSync-text{font-size: 10px!important}
}

@media (max-width: 426px) {
  .mainHeader {
    margin: 0;
  }
  .languageDiv{
    margin: 0px 7px 0px 3px;
  }
  .notificationDiv {
    margin: 0px 5px 0px 0px;
  }

  /* .hamburgerMenuButton {
    display: block;
  } */
}
@media (max-width: 400px) {
  .header-page-name{ font-size: 12px;}

}


@media (max-width: 1025px) {

  .hamburgerMenuButton {
    display: block;
  }
}