.interest-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: #313131;
}
.interest-account-box {
  background: linear-gradient(248.17deg, rgb(130, 200, 237) 0%, rgb(162, 196, 250) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  height: auto;
  min-height: 180px;
  padding: 9px 9px;
  font-size: 15px;
}
.exchange-content {
  display: flex;
  justify-content: space-between;
}
ul.ex_rates_list {
  list-style: none;
}
ul.ex_rates_list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
}
li div img.img-flag {
  border-radius: 50%;
  width: 26px;
  height: 26px;
}
.currency-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-top: 30px;

  color: #313131;
}
.amount-text {
  font-style: normal;
  font-size: 12px;
  /* identical to box height */
  margin: 0px;
  color: #313131;
}
.load-more-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: #313131;
  margin-bottom: 10px;
  cursor: pointer;
}
.main-all-currencies {
  display: flex;
  justify-content: center;
}
.exchange-loading {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.loadingg {
  margin-top: 50px;
}
.message-container {
  display: flex;
  justify-content: center;
}
.message-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  margin-top: 20px;
  margin-left: 5px;
  color: #313131;
}
.valid-for {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.valid-test {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: #313131;
  margin-bottom: 10px;
}
/* .refresh-icon-container {
  display: flex;
  justify-content: end;
  padding: 4px 12px;
} */
.refreshIcon {
  cursor: pointer;
}
