#root {
  min-height: 100vh;
}
.ForgotPassword {
  display: flex;
  min-height: 100vh;
}
.onBoardingForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.forgot-title {
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 13px;
}
.forgot-text {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 46px;
}
.forgot-form {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
/* .email-inp-text {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
} */
.forgot_email-inp {
  margin-bottom: 40px;
  height: 50px;
  background: #edf2f7;
  border: none;
  border-radius: 5px;
  font-family: inherit;
  padding: 0.375rem 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.forgot_email-inp::placeholder {
  font-weight: 500;
  font-size: 20px;
  color: #cbcace;
}
.forgot_subm-btn,
.forgot_backtolog-btn {
  height: 50px;
  background: #60a3f3;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  font-size: 20px;
  color: white;
  cursor: pointer;
}
.forgot_subm-btn {
  font-weight: bold !important;
  margin-bottom: 30px;
}
.forgot_subm-btn:hover,
.forgot_backtolog-btn:hover {
  background-color: #92bafb;
}


.backToLogin {
  display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding: 10px;
  color: #006FF4;
}
.backToLogin span {
  cursor: pointer;
  font-weight: 500;
}

@media (max-width: 540px) {
  .onBoardingForm {
    justify-content: start;
  }
}