/* .cardsSettingsDiv {} */

/* Card buttons css */
.mainBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  /* exact figma */
  /* box-shadow: 0px 0px 6px 2px #001B3A0F; */
  box-shadow: 0px 0px 4px 3px #001B3A0F;

  width: 32% !important;
  cursor: pointer;
  margin: 12px 0px;
}

.disabledBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 32% !important;
  cursor: pointer;
  margin: 13px 0px;
  padding: 0 !important;
}

.disabled-mainBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #eee;
}

.mainBox-icon {
  background: #d7f0ff;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  max-width: 50px !important;
}

.mainBox-text {
  font-size: 14px;
  font-weight: 500;
}

.mainBox-SuffixIcon {
  text-align: end;
}

/* Card Dropdown css */
.mainBox #dropdownMenuButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  padding: 10px 20px;
}

.mainBox .dropdown-menu-button {
  padding: 15px;
}

.listBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardSettingsOptionsDiv {
  background: #FFFFFF;
  border-radius: 16px;
  width: "100%";
}
.cardSettingBox{
  display: flex;
  column-gap: 16px;
}
@media screen and (max-width: 1136px) {
  .cardSettingBox {
    justify-content: space-between;
  }
  .cardSetting-MainBox {
    width: 48.5% !important;
  }
}

@media (max-width: 1024px) {
  .mainBox {
    width: 49% !important;
  }

  .disabledBox {
    width: 49% !important;
  }
}
@media screen and (max-width: 991px) {
  .cardSetting-MainBox {
    width: 48.5% !important;
  }
}

@media (max-width: 768px) {
  .mainBox {
    width: 100% !important;
  }

  .disabledBox {
    width: 100% !important;
  }
}

@media (min-width:769px) and (max-width:980px) {
  .mainBox-icon {
    width: 35px !important;
  }
}