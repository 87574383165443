.accordianIcon {
    border: none;
    border-radius: 6px;
    background: #006ff4;
    width: 60px;
    height: 60px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordianSummary {
    text-align: justify;
    margin-left: 15px;
}

.accordianTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #231F20;
    text-align: left;
  
}

.accordianDes {
    color: #ADB6C0;
    font-size: 14px;
    margin: 0;
}


  @media screen and (min-width:541px) and (max-width:1000px) {
    .accordianTitle {
        font-size: 15px;
    }
    .accordianDes {
        font-size: 12px;
    }
  }
  @media screen and (max-width: 440px) {
    .accordianTitle {
        font-size: 14px;
    }
    .accordianDes {
        font-size: 12px;
    }
  }