.card-send-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../../../assets/images/revamp/bgTimerSection.svg') center/cover no-repeat;
    background-color: #006ff4 !important;
    padding: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    height: 205px !important;
    border-radius: 20px !important;
    border: none !important;
}

.verify-titlee {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    z-index: 5;
}