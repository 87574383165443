.cstm-btn {
  /* display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 4px; */
}
.icon-select {
  align-self: center;
  margin-right: 4px;
}
/* .form-select {
  min-width: 120px;
} */

.languageDropdown{
  width: 170px;
  position: relative;
    /* background: red; */
}
.dropdownOpen{
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
}
.dropdownOpen svg {
  color: #006FF4;
}
.langIcon{
  position: absolute;
  margin-top: 5px;
  margin-left: 11px;
}
.lang{
  border: 2px solid #D7F0FF;
  border-radius: 10px;
  padding: 5px;
  position: relative;
}
.lang p{
margin: 0;
padding-left: 35px;
font-weight: 600;
color: #231F20;
}

.languageList{
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 2px #001B3A0F;
    z-index: 99;
}
.langDropDownListShow{
display: block;
}
.langDropDownListHide{
display: none;
}