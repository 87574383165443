.recent-op-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #231F20;
  margin-left: 10px;
}

.recent-op-rates-box {
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.img-brandname-op {
  display: flex;
  align-items: center;
}

.brandName-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  padding: 20px;
  color: #313131;
}

.titile-see-all-button {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.recent-op-text-div {
  /* width: 78%; */
}

.load-more-text-recent-div {
  /* width: 22%; */
  /* margin-right: 18px; */
}

.load-more-text-recent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #006FF4;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 18px;
}

.date-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 27px;
  color: #868686;
}

/* .amount-op-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #1A1A1A !important;
} */

.three-dot-op {
  cursor: pointer;
  font-size: 20px;
}

.negative-op-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #313131;
}

.negative-op-text-new {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1A1A1A ;
}

.amount-op-text-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 27px;
  color: #313131;
}

.negative-op-text-new-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #313131;
}

.brandName-text-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  padding: 20px;
  color: #313131;
}

.date-text-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 27px;
  color: #868686;
}

.currency-op-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #313131;
}

ul.op_list {
  list-style: none;
}

ul.op_list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}

li div img.img-brand {
  width: 25px;
  height: 23px;
}

.no-data-container {
  display: flex;
  justify-content: center;
}

.no-recent-op {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 30px;
  color: #313131;
}

/* table styling */
table.table-recentOpt tbody tr td {
  font-weight: 400;
  font-size: 18px;
  color: #1A1A1A;
  padding: 10px 10px !important;
}

/* table.table-recentOpt tbody tr td:nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #313131;
} */

table.table-recentOpt tbody tr td:last-child {
  color: #868686;
  overflow: visible;
}

.table-recentOpt tbody tr td:first-child {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  white-space: nowrap;
}

.table-recentOpt tbody tr td:nth-child(2n) {
  max-width: 170px;
  white-space: nowrap;
  text-align: 'center';
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-recentOpt tbody tr td:nth-child(3n) {
  /* text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px; */
  white-space: nowrap;
}

.table-recentOpt tbody tr td:nth-child(4n) {
  white-space: nowrap;
  /* max-width: 180px; */
  overflow: hidden;
  text-overflow: ellipsis;
}


.table-recentOpt tbody tr td:nth-child(5n) {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-recentOpt tbody tr td:nth-child(6n) {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.dropdown-menu li .dropdown-item {
  font-size: 15px;
}

/* Revamp Css start */
.allRecentOperationsTable table tbody tr {
  border: 1px solid #FFFFFF;
}
.transactionDetailsModal .modal-content{
  width: 90%;
}

.transactionDetailHeading{
  font-size: 24px;
  font-weight: 600;
  color: #231F20;
}

.transactionDetail-subHeading{
  font-size: 16px;
  font-weight: 400;
  color: #231F20;
}

.transactionDetailsModal .modal-content {
  --mask:
  radial-gradient(10.11px at 50% 11.50px,#000 99%,#0000 101%) calc(50% - 20px) 0/40px 51% repeat-x,
  radial-gradient(10.11px at 50% -1.5px,#0000 99%,#000 101%) 50% 10px/40px calc(51% - 10px) repeat-x,
  radial-gradient(10.11px at 50% calc(100% - 11.50px),#000 99%,#0000 101%) calc(50% - 20px) 100%/40px 51% repeat-x,
  radial-gradient(10.11px at 50% calc(100% + 1.50px),#0000 99%,#000 101%) 50% calc(100% - 10px)/40px calc(51% - 10px) repeat-x;
-webkit-mask: var(--mask);
        mask: var(--mask);
}
.transactionDetailsModal .modal-header {margin-top: 30px;}


.paymentAmountAmount{
  font-size: 26px;
    color: #006FF4;
    font-weight: 600;
}

/* Revamp Css start */

/* media queirs */

@media (max-width: 576px) {
  table.table-recentOpt tbody tr td {
    font-size: 8px;
    padding: 2px 4px 2px 2px !important;
  }
  .recent-op-text {
    font-size: 16px !important;
    line-height: 10px;
    margin-left: 0px;
  }
  .load-more-text-recent { 
    font-size: 12px;
    margin-right: 10px;
  }
  .three-dot-op {
    font-size: 16px;
  }
  /* Recent Op modal responsiveness */
  /* .allRecentOperationsTable div .modalHeader {padding-left: 0px;} */
  .allRecentOperationsTable div .modal-body div > div {margin: 7px 0px 0px;}
  .allRecentOperationsTable div .modal-body div div div .search-input-recent-op-modal {padding: 3px 10px; font-size: 16px;}
  .table-recentOpt-modal {margin-bottom: 0px !important;}
  .table-recentOpt-modal tbody tr td{font-size: 10px !important;vertical-align: middle;padding: 1px;}
  .table-recentOpt-modal tbody tr td .dropdown { margin: 0px 0px 2px 0px;}
  .table-recentOpt-modal tbody tr td {height: 10px; width: 10px; margin: 0px !important;}
  .table-recentOpt-modal tfoot nav ul li div { height: 32px; font-size: 12px;}

    /* Transactions details modal responsiveness */
  .transactionDetailsModal .modal-content {width: 100%;}
  .transactionDetailsModal-body .text-center .transactionDetailHeading {font-size: 18px;}
  .transactionDetailsModal-body .text-center .transactionDetail-subHeading {font-size: 14px;}
  .transactionDetailsModal-body .text-center .paymentAmountAmount {font-size: 18px;}
  .transactionDetailsModal-body .row {font-size: 12px;}

}



@media (max-width: 430px) {
  .transactionDetailsModal .modal-content{
    width: 100% ;
  }
  .no-recent-op{
    font-size: 16px;
  }
  
}