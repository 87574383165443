.setting-main {
    margin: 1rem !important;
}

.settingFormBox {
    background-color: #FFFFFF;
    border-radius: 15px;
}

.settingFormWidth {
    width: 470px;
    margin: 0px auto;
    padding: 15px 30px 20px 30px;
}

.onBoarding-title {
    font-weight: 700;
    font-size: 30px;
    color: #231F20;
}

@media (max-width: 1120px) {
    .settingFormWidth {
        width: 100%;
    }
}