/* .card-balance-box {
  width: 100%;
  height: auto;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 0px 36px 20px 18px;
}
.card-balance-box_wrapper {
  width: 100%;
  height: 100%;
  padding: 23px 35px 24px 26px;
  display: flex;
} */
.card-balance-box-text {
  height: 100%;
  width: 200px;
  padding: 31px 20px 0px 30px;
}
.acc-balance {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.balance {
  font-weight: 600;
  font-size: 40px;
  color: #313131;
  margin-bottom: 60px;
}
.balance-currency {
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  margin-top: 29px;
}
.balance::after {
  font-weight: 700;
  font-size: 20px;
}
.change-card-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.change-card-text {
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  margin-right: 5px;
  margin: 0;
}
.change-btn {
  border: none;
  cursor: pointer;
  background-color: #ffffff;
}
.card-balance-btn-wrapper {
  height: 100%;
  width: 240px;
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
}
.add-founds-btn,
.send-founds-btn,
.convert-founds-btn {
  width: 74px;
  height: 100%;
  background: #e9e9e9;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
  font-weight: 600;
  font-size: 8px;
  color: #313131;
  padding: 20px 0px 11px 0px;
  cursor: pointer;
}


/* Revamp Css */

.AccountInfoActionCards{
  border: none !important;
  padding: 6px 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  cursor: pointer;
  border-radius: 10px !important;
}
.accountBalance{
  display: flex;
    justify-content: center;
    align-items: center;
  font-weight: 400;
  font-size: 14px;
  background: #006FF4;
    color: #D4E6FC;
    padding: 10px;
    border-radius: 15px;
    text-align: center;
    margin-bottom: 30px;
}
.accountBalance p{
margin-bottom: 0;
}

.balanceTitle{
  font-size: 15px;
  font-weight: 500;
  color: #D4E6FC;
}

.convertFundsCard{
  margin-top: 30px;
}
.accountBalance .amount {
  color: #FFFFFF;
  margin-left: 15px;
  margin-right: 5px;
  font-size: 18px;
  font-weight: 600;
}

.accountBalance .currency {
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 600;
}

.generalInfoDiv{
  background: white;
    /* height: 300px; */
    border-radius: 15px;
    padding: 15px;
}
.generalInfoDiv .bankAccountCard .text-content-card-general div svg:nth-child(2){
  display: none;
}

.generalInfoDiv .bankAccountCard {
  padding: 10px 15px;
}
.actionCardImg{
  background-color: #D7F0FF;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.actionCardTitle{
  font-size: 16px;
  font-weight: 500;
  color:#231F20;
}
.transferMoneyCard-col {
  /* padding: 6px 10px 20px 10px; */
}
/* Media Queries */
@media (max-width:1024px) {

  .actionCardImg{
    width: 40px;
    height: 45px;
  }
  .convertFundsCard{
    margin-top: 30px;
  }
}

@media (max-width:770px) {
  .transferMoneyCard{
    margin-top: 15px;
  }
  .actionCardImg{
    width: 50px;
    height: 50px;
  }
  .convertFundsCard{
    margin-top: 20px;
  }
}
@media (max-width:576px) {
  .convertFundsCard {margin-top: 15px;}
  .transferMoneyCard {margin-top: 0px;}
  .generalInfoDiv .bankAccountCard { padding: 10px 10px 7px 10px; margin-right: 10px;}
  .transferMoneyCard .card-body, .convertFundsCard .card-body {padding: 9px;}
  .actionCardTitle{font-size: 9px;}
  .actionCardImg {height: 30px; width: 30px;}
  .generalInfoDiv .accountBalance {margin-bottom: 12px !important; font-size: 12px;}
  .generalInfoDiv .accountBalance .balanceTitle { font-size: 12px;}
  .generalInfoDiv .accountBalance .amount, .generalInfoDiv .accountBalance .currency { font-size: 16px;}
  .transferMoneyCard-col {
    padding: 5px !important;
  }
  
}
@media (max-width:430px) {
  .accountBalance .currency, .accountBalance .amount{
    font-size: 16px;
  }
  .generalInfoDiv {padding: 12px 8px 8px 8px;}

}
@media (max-width:376px) {
  /* .transferMoneyCard .card-body, .convertFundsCard .card-body {padding: 8px;} */
  .generalInfoDiv .bankAccountCard {  margin-right: 5px;}


}
@media (max-width:356px) {
  .generalInfoDiv {padding: 6px;}
  
}