.questionaire {
    display: flex;
    min-height: 100vh;
}

.questionaire .mainTitle {
    margin: 25px 0px 10px 0px;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
}

.questionaire .MuiStepper-horizontal {
    padding-right: 15%;
    padding-left: 15%;
}
.questionaire .inputFlex {
    margin-bottom: 10px;
}
.questionaire .flexInput {
    display: flex;
    align-items: center;
    gap: 20px;
}
.questionaire .flexInput span {
    margin-bottom: 15px;
}
.questionaire .inputText {
    width: 100%;
}

.questionaire .backButton {
    width: 100%;
    background-color: white;
    color: #006FF4;
    border: 1px solid #006FF4;
    border-radius: 6px;
    font-weight: 600;
}

.questionaire .chooseFileBtn button {
    margin-top: 0px;
    background-color: #006FF4;
    border-radius: 6px;
    border: none;
    outline: none;
    color: white;
    font-size: 12px;
    font-family: "Montserrat", sans-serif !important;
    padding: 12px 35px 12px 35px;
    font-weight: 600;
}

.questionaire .textFont {
    font-family: "Montserrat", sans-serif !important;
}

.questionaire .inputfixcls .MuiFormControlLabel-label {
    width: 100%;
}

.questionaire .textmarginfix p {
    margin-bottom: 0px;
}
.questionaire .error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
.questionaire .onBoarding-title {
    margin-bottom: 30px;    
}
.questionaire .inputLabel {
    display: inline;
    margin-right: 4px;
}
.textarea_style {
    border: 2px solid #D7F0FF;
    height: 80px;
    width: 100%;
    border-radius: 8px;
    padding: 5px;
}

.questionaire .skipText {
    color: #006FF4;
    text-align: end;
    font-weight: 500;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: end;
}
.questionaire .MuiAutocomplete-inputRoot {
    border-radius: 10px !important;
    margin-top: 8px;
    border: 2px solid #D7F0FF !important;
    font-family: Montserrat !important;
}