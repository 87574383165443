.Bank-info-main {
  min-height: 100vh;
  margin: 13px;
  /* background-color: #f5f6f8; */
}
/* .modal-body{
  max-height: 100vh; 
  overflow-y: auto !important;
  
} */

.generalInfo-heading {
  width: 265px;
  height: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #231F20;
}

.heading_title {
  padding-bottom: 20px;
}

.payment-receiver-contentt {
  margin-top: 5px;
  /* display: flex;
  justify-content: space-between; */
}

.payment-receiver-content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-see-all-button {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
}

.payment-receiver-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #231F20;
}

.load-more-text-payments {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #313131;

  cursor: pointer;
}

.border-dashed-card {
  /* width: 185px; */
  height: 160px;
  border: 2px dashed #4b9af4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
}

.modal-inp {
  width: 100% !important;
  height: 50px !important;
  background: #edf2f7 !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.375rem 1.75rem !important;
  margin-bottom: 10px !important;
}

.modal-inp-text {
  font-weight: 600;
  font-size: 20px;
  color: #313131;
  margin-bottom: 2px;
  margin-left: 3px;
}

.btn-modal {
  display: flex !important;
  justify-content: space-between !important;
}

.btn-addbeneficiary {
  background: #86b6ef;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  height: 40px;
  border: none;
  padding: 10px;
  margin-top: 10px;
}

.bank-card {
  display: flex;
  padding: 10px 0px 10px 0px;
  /* justify-content: space-between; */
}

/* .main-payment-receivers-container {
  display: flex;
  flex-direction: row;
} */

.skeleton-container {
  display: flex;
  justify-content: space-between;
}

.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 36px;
  background-color: white;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  /* display: inline-block; */
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.modal-body {
  padding: 15px 30px 15px 30px !important;
}

/* Revamp BankAccount Css */
.bacnkAccount-SectionBackground {
  background: rgb(255, 255, 255);
  border-radius: 15px;
  padding: 1rem 0rem 1rem 0rem;

}


.materialActionsTable .tss-1x5mjc5-MUIDataTable-root {
  box-shadow: none;
}

.materialActionsTable .MuiToolbar-root {
  padding: 0px 12px;
  display: flex;
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr th,
.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr td {
  border: none;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table .tss-1937jnc-MUIDataTableHead-main {
  /* background-color: #006FF4 !important;
  background: #006FF4 !important; */
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table .tss-1937jnc-MUIDataTableHead-main .tss-1832qqn-MUIDataTableHeadRow-root {
  /* background-color: #006FF4 !important;
  background: #006FF4 !important; */
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table thead tr th {
  /* background-color: #006FF4 !important; */
  color: #231F20;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
  white-space: nowrap;
}
.MuiTableHead-root .MuiTableRow-root {
  height: 20px !important;
}
.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr th:first-child {
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
  /* padding-left: 15px!important; */
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr th:last-child {
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot {
  /* display: block; */
  display: flex;
  justify-content: end;
  align-items: center;
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div p,
.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div .MuiTablePagination-input,
.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div .MuiTablePagination-displayedRows {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div p {
  /* font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold; */
  margin-bottom: 2px;
}

/* .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div .MuiTablePagination-input{
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold;
} */
.materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div .MuiTablePagination-displayedRows {
  /* font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold; */
  margin-bottom: 0px;
  margin-top: 1px;
}


.allPaymentReceiverList thead tr th {
  padding: 5px 5px !important;
}

.allPaymentReceiverList thead tr th button {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.allPaymentReceiverList thead tr th .tss-1akey0g-MUIDataTableHeadCell-data,
.allPaymentReceiverList tbody .tss-1qtl85h-MUIDataTableBodyCell-root div {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
}


/* .materialActionsTable .tss-qbo1l6-MUIDataTableToolbar-actions button svg {
  color: #FFFFFF;
  background-color: #006FF4;
  border-radius: 50%;
  padding: 5px;
} */


.header-bg-blue  table thead {
  
}
.header-bg-blue thead th{
  background-color: #006FF4 !important;
  color: #FFFFFF !important;
  /* background: #006FF4 !important; */
 
}
.header-bg-blue thead th span button {
  color: #FFFFFF;
}


.header-bg-blue thead th:first-of-type{
  border-top-left-radius: 12px; 
  border-bottom-left-radius: 12px; 
}

.header-bg-blue thead th:last-of-type{
  border-top-right-radius: 12px; 
  border-bottom-right-radius: 12px; 
}
.RecuringTable thead th {
  padding: 13px 5px;
  font-size: 18px;
  font-weight: 600;
  /* font-family: "Montserrat", sans-serif !important; */
}

.RecuringTable tbody td {
  /* font-family: "Montserrat", sans-serif !important; */
}

/* Non Active user screen  */
.nonActiveUserDiv {
  background-color: #FFFFFF;
  border-radius: 15px;
  text-align: center;
}

.thanksForRegisteringtext {
  font-size: 23px;
  color: #231F20;
  font-weight: 600;
}

.detialstext {
  font-size: 18px;
  font-weight: 400;
  color: #ADB6C0;
}
.pay-scheduling-table .btn-transfer {
  padding: 6px 0px; 
  border-radius: 8px;
  border: none;
  width:100%;
  color: #ffffff;
  font-size: 16px;

}
.approve-btn-class{
  font-size: 10px !important;
  font-weight: 600 !important;
}
.scheduling-check-approve-icon{
  width: 68px;
  min-width: 68px;
}


/* Non Active user screen  */

/* Revamp BankAccount Css */


@media (max-width: 1200px) {

  .allPaymentReceiverList thead tr th .tss-1akey0g-MUIDataTableHeadCell-data,
  .allPaymentReceiverList tbody .tss-1qtl85h-MUIDataTableBodyCell-root div {
    font-size: 12px;
  }

  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr th,
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr td {
    padding: 10px 0px 10px 8px !important;
  }

  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div p,
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div .MuiTablePagination-input,
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tfoot tr td div div div .MuiTablePagination-displayedRows {
    font-size: 12px;
  }
}
.new-card-payment-container{
  margin: 0px 20px 0px 5px;
}

@media (max-width: 992px) {
  .my-modal {
    min-width: 90%;
  }
}

@media (max-width: 576px) {
  /* payment receiers modal */
  .modal-body {padding: 15px 10px 15px 10px !important;}
  .my-modal .modalHeader .modal-title {margin-left: 0px; }
  .materialActionsTable .tss-1fbujeu-MUIDataTableHeadCell-toolButton{ margin-right: 0px;}
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr th,
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr th button,
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr td {
    padding: 4px 2px 4px 2px !important;
    font-size: 8px;
    margin: 0 !important;
    min-width: 64px !important;
  }
  .materialActionsTable .tss-1fz5efq-MUIDataTableToolbar-left{padding-top: 0px !important;}
  .materialActionsTable .tss-1fz5efq-MUIDataTableToolbar-left svg, 
  .materialActionsTable .tss-1fz5efq-MUIDataTableToolbar-left .tss-52j81d-MUIDataTableSearch-clearIcon { 
    margin: 0px !important; 
    padding: 0px !important; 
    width: 16px !important;
    height: 16px !important;
  }
  .materialActionsTable .tss-qbo1l6-MUIDataTableToolbar-actions button img,
  .materialActionsTable .tss-qbo1l6-MUIDataTableToolbar-actions button svg { width: 16px;height: 16px;}
  .materialActionsTable .tss-qbo1l6-MUIDataTableToolbar-actions button {padding: 5px;}
  .materialActionsTable .MuiToolbar-root {padding: 0px 4px;}  
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tr th:first-child {padding-left: 5px !important;}
  
  .materialActionsTable .tss-1x5mjc5-MUIDataTable-root table tbody tr td img {width: 12px; height: 14px;}
  .allPaymentReceiverList thead tr th .tss-1akey0g-MUIDataTableHeadCell-data {font-size: 10px;}
  .allPaymentReceiverList tbody .tss-1qtl85h-MUIDataTableBodyCell-root div{font-size: 8px;}
  .MuiTableHead-root .MuiTableRow-root {height: 30px !important;}

  .bank-card { padding: 0px !important;}
  .Bank-info-main {margin: 0px;padding: 0px 10px 0px 10px;}
  .monthlyRevenueTrackerDiv {display: none;}
  .payment-receiver-content-box{
    padding-left: 10px !important;
  }
  .payment-receiver-text {font-size: 16px !important;}

  /* Account Details Modal */
  .account-detail-modal-root div div .modalHeader .headerDiv .modal-title{ margin-left: 0px; font-size: 16px;}
  .account-details-table {margin-bottom: 0px !important;}
  .account-details-table tbody tr td { font-size: 12px;}

  /*Paymenet receiver moda => Edit benificiety  Modal */
  .edit-benificiety-modal-root .modal-title{ margin-left: 0px; font-size: 16px;}
  .edit-benificiety-modal-root p { margin-left: 8px; margin-bottom: 0px; font-size: 14px;}
  .edit-benificiety-modal-root .formInputLabel { margin-top: 20px;}
  .edit-benificiety-modal-root .inputTypeText {font-size: 14px}
  .edit-benificiety-modal-root .btn-send {margin-top: 0px;}
  .edit-benificiety-modal-root button {font-size: 14px;}
  /* Payment receivers modal top icons */
  .my-modal .tss-qbo1l6-MUIDataTableToolbar-actions img {width: 30px;height: 30px; }
  /* recurring table and scheduling table padding top on small screen reduced */
  .bacnkAccount-SectionBackground {padding: 0.2rem 0rem 0rem 0rem;}
  .pay-scheduling-table .btn-transfer {
    padding: 6px 4px !important;
    font-size: 8px;

  }
  .materialActionsTable .tss-qbo1l6-MUIDataTableToolbar-actions {text-align: right;}

  .thanksForRegisteringtext {
    font-size: 16px;
  }
  
.detialstext {
  font-size: 12px;
}
.non-active-user-img {width: 70px !important;}
.css-1dkc8sk-MuiFormControl-root-MuiTextField-root {padding: 0px !important;}
.approve-btn-class{
  padding: 4px  !important;
  font-size: 9px !important;
}
.scheduling-check-approve-icon{
  width: 50px;
  min-width: 50px;
}


}


@media (max-width: 426px) {
  .main-payment-receivers-container {
    /* flex-direction: column; */
  }
  
}