.recuringTbl {
    font-family: "Montserrat", sans-serif !important;
    font-size: 24px;
    font-weight: 600;
    color: #231F20;
}
.details-header thead th:first-of-type{
    border-top-left-radius: 12px; 
    border-bottom-left-radius: 12px; 
  }
  
  .details-header thead th:last-of-type{
    border-top-right-radius: 12px; 
    border-bottom-right-radius: 12px; 
  }

@media (max-width: 576px) {
    .recuringTbl {
        font-size: 16px !important;
    }
    .status-flag{
      padding: 4px 8px !important;
      font-size: 8px  !important;
    }
}