#root {
  min-height: 100vh;
}

.Registration {
  display: flex;
}

.reg-form {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.point-box {
  float: right;
  height: 10px;
  width: 40px;
  display: flex;
  justify-content: space-around;
}

.left-point,
.middle-point,
.right-point {
  background-color: #c0d5ed;
  width: 10px;
  height: 100%;
  border-radius: 50%;
}

/* .company-text,
.companyadd-text,
.reg_email-text,
.phone-text,
.createpass-text {
  font-weight: 500;
  font-size: 20px;
  color: #313131;
} */
/* .company-inp,
.companyadd-inp,
.reg_email-inp,
.phone-inp,
.createpass-inp {
  margin-bottom: 30px;
  height: 50px;
  background: #edf2f7;
  border: none;
  border-radius: 5px;
  font-family: inherit;
  padding: 0.375rem 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
} */
.reg_btn {
  background-color: #60a3f3;
  border-radius: 5px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  height: 50px;
  border: none;
  margin-bottom: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.reg_btn:hover {
  background: #92bafb;
}

.company-inp::placeholder,
.companyadd-inp::placeholder,
.reg_email-inp::placeholder,
.phone-inp::placeholder,
.createpass-inp::placeholder {
  font-weight: 500;
  font-size: 20px;
  color: #cbcace;
}

.terms-conditions {
  display: flex;
  /* justify-content: center; */

  /* align-items: center; */
  margin-top: 20px;
}

.terms-conditions .form-check-input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #006FF4;
}

.terms-conditions a {
  text-decoration: none;
}

.term-condition-text {
  /* font-style: italic; */
  color: #231F20;
  font-size: 16px;
}
.term-condition-link{
  font-weight: 500;
}
.countryPhoneInput{
  width: 100% !important;
  height: 2.85rem !important;
  background: #edf2f7 !important;
  border: none !important;
}



/* Revamp Css  */
.userNameDiv {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.userName-firstName,
.userName-lastName {
  width: 49%;
}

.registrationPageIcon {
  top: 33% !important
}

.edit-profile {
  margin: 1rem !important;
}

@media (max-width: 765px) {
  .userNameDiv {
    display: block;
  }

  .userName-firstName,
  .userName-lastName {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .edit-profile {
    margin: 0.4rem !important;
  }

  .onBoarding-title {
    font-size: 20px;
  }
  .term-condition-text{
    font-size: 14px;
  }
}

/* Revamp Css  */