.enter-num-inp {
  width: 100%;
  height: 50px;
  background: #edf2f7;
  border-radius: 5px;
  border: none;
  padding: 0.375rem 1.75rem;
  margin-bottom: 10px;
}

.enter-num-inp::-webkit-outer-spin-button,
.enter-num-inp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.enter-num-inp[type="number"] {
  -moz-appearance: textfield;
}


.inputTypeText {
  width: 100%;
  border: 2px solid #D7F0FF;
  border-radius: 10px;
  padding: 10px 15px;
  background-color: #fff;
}

.inputTypeText:disabled {
  background-color: #F8F8F8;
}

 .css-1igl0p7-MuiAutocomplete-root .MuiOutlinedInput-root {
 padding: 2px 10px !important;
 font-family: "Montserrat";
}
.MuiAutocomplete-paper {margin-top: 2px;} 
.MuiAutocomplete-paper ul li{
  font-family: "Montserrat";
}

