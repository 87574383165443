.bank-acc-img-sb {
  margin: 0px 15px 0px 15px;
  width: 30px;
  height: 20px;
}

.list-style-none {
  list-style: none;
  margin-top: 5px;
  margin-bottom: 0px;
  z-index: 1;
  position: absolute;
  top: 135px;
  width: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.switch-account-btn {
  width: 100%;
  text-align: left;
  background-color: #FFFFFF;
}

.switch-account-btn:hover {
  background: rgb(240, 240, 240);
  /* color: #FFFFFF; */
  font-weight: bold;
}

.createNewAccount {
  /* float: right; */
  position: relative;
  background: #006ff4;
  border-radius: 10px;
  padding: 10px 10px;
  color: #FFFFFF;
  width: 300px;
  
  cursor: pointer;
}

.accountList {
  position: absolute;
  top: 11px;
  /* margin-top: 5px; */
  /* display: none; */
}
@media (max-width: 576px) {
 .accountList { position: relative; top: 2px; }
.list-style-none { top: 0px; width: 100%; }

}
@media (max-width: 430px) {
  .createNewAccount{
    width: 100% !important;
  }
}